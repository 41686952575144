<template xmlns:template="http://www.w3.org/1999/html">

  <container v-if="$route.params.programId" :loading="detailsLoading" id="course-enrollment" min-height="auto" class="border rounded shadow p-4 bg-white">
    <template #heading>
      <div class="text-2xl">
        Course Enrollment
      </div>
    </template>

    <form class="md:col-span-2 flex flex-col min-h-60" method="post"  @submit.prevent="performEnrollment">
      <div class="text-lg font-semibold text-center mb-1">{{ batchItem.displayName }}</div>
      
      <div class="text-sm font-semibold text-center mb-8 text-green-500">
        <span class="font-bold">Course:</span> <span>{{ batchItem.courseName }}</span> <span class="text-gray-300">-</span> <span> {{ batchItem.batchYear }}</span>
      </div>

      <div class="text-sm text-center mb-6 text-orange-800">Before Enroll, please provide required information</div>

      <div class="flex items-center flex-col" v-if="programHasGroup">

          <input-dropdown v-if="areaSelectionNeeded" :items="areaList" v-model="selected.area_id" size="sm" searchable clearable class="mb-4 w-full md:w-3/6 mt-6" required>
            <template v-slot:label>Select your area, district, division</template>
          </input-dropdown>

          <input-dropdown v-if="collegeSelectionNeeded" :items="collegeList" v-model="selected.college_id" size="sm" searchable clearable class="mb-4 w-full md:w-3/6 mt-6">
            <template v-slot:label>Select your college</template>
          </input-dropdown>

      </div>

      <div class="flex justify-center" v-if="candidateTypeSelectionNeeded">
        <input-dropdown :items="candidateTypes" v-model="selected.candidate_type"  clearable class="mb-4 w-full md:w-3/6 mt-6" size="sm">
          <template v-slot:label>Are you first timer or second timer?</template>
        </input-dropdown>
      </div>

      <div class="flex justify-center" v-if="educationMediumSelectionNeeded">
        <input-dropdown :items="mediums" v-model="selected.medium"  clearable class="mb-4 w-full md:w-3/6 mt-6" size="sm">
          <template v-slot:label>What is your medium, Bangla or English?</template>
        </input-dropdown>
      </div>

      <div class="flex justify-center" v-if="slotSelectionNeeded">

        <input-dropdown :items="programSlots" v-model="selected.program_slot_id"  size="sm" :clearable="programSlots && programSlots.length > 1" class="mb-4 w-full md:w-3/6 mt-6">
          <template v-slot:label>Slots</template>

          <template v-slot:selection-item="{item}">
            <div class="text-xs font-semibold mb-1">{{item.name}} &nbsp;&nbsp;</div>
            <div class="flex justify-between w-full border-t border-dashed pt-1">
              <div>{{item.daysDisplayTitle}}</div> &nbsp;&nbsp;<div>{{item.timesDisplayTitle}}</div>
            </div>
          </template>

        </input-dropdown>
      </div>

      <div v-if="!enrollMessage" class="text-center mt-12" >Are you sure want to enroll?</div>

      <status-message v-else :message="enrollMessage" :status-code="enrollStatus" class="text-center mt-5" />

      <div class="mt-6 w-full flex justify-between self-end">
        <btn variant="warning-outline" class="mt-6" @click.prevent="$router.back()">
          Back
        </btn>
        <btn type="submit" variant="success" class="mt-6">
          Enroll
        </btn>
      </div>

    </form>

  </container>

</template>

<script>
import Overly from "../../../components/overly";
import Spinner from "../../../components/spinner";
import BatchItem from "../home/components/batch-item";
import Container from "../../layouts/container";
import Btn from "../../../components/btn";
import ProgramEnrollBefore from "../../student/courses/components/program-enroll-before";
import InputDropdown from "../../../components/input/input-dropdown";
import InputText from "../../../components/input/input-text";
import StatusMessage from "../../../components/status-message";
import {createElementVNode} from "vue";
export default {
  name: "program-details",
  components: {StatusMessage, ProgramEnrollBefore, Btn, Container, BatchItem, Spinner, Overly, InputDropdown, InputText},
  data() {
    return {
      loading: true,
      batchItem: {},
      detailsMaxHeight: '350px',
      collegeList: [],
      areaList: [],
      areaSelectionNeeded: false,
      collegeSelectionNeeded: false,
      candidateTypeSelectionNeeded: false,
      educationMediumSelectionNeeded: false,
      slotSelectionNeeded: false,
      programHasGroup: false,
      selected: {area_id: '',college_id: '', candidate_type: '', program_slot_id: '', medium: ''},
      candidateTypes: [
        {name:'First Timer', id:1},
        {name: 'Second Timer',id:2}
      ],
      mediums: [
        {name:'Bangla Medium', id:1},
        {name: 'English Medium',id:2}
      ],
      programSlots: [],
      enrollMessage: 'Hello...',
      enrollStatus: 200,
      detailsLoading: true
    }
  },
  //programId/:courseName?/:batchName?

  computed:{

    batchNameSlug(){
      return this.$route.params.batchName;
    },
    courseNameSlug() {
      return this.$route.params.courseName;
    },
    programId() {
      return this.$route.params.programId;
    },

  },
  methods:{

    testEvent(e){

    },
    loadProgram() {},
    performEnrollment(){

      //console.log( this.selected )

      //alert("Hello...");

      this.enrollToProgram({
        programId: this.programId,
        options: this.selected,
        onError: ( data, status ) => {
          this.enrollMessage = data.message;
          this.enrollStatus = status;
        }
      });

    }

  },
  created() {

    // this.batchItem = this.$store.state.batch.programDetails;

    this.$store
      .dispatch( 'batch/programEnrollBefore', {
        params: { program_id: this.$route.params.programId },

        success: ( {data} )=> {
          this.batchItem = data.program;
          this.collegeList = data.colleges;
          this.areaList = data.areas;
          this.areaSelectionNeeded = data.areaSelectionNeeded;
          this.collegeSelectionNeeded = data.collegeSelectionNeeded;
          this.candidateTypeSelectionNeeded = data.candidateTypeSelectionNeeded;
          this.educationMediumSelectionNeeded = data.educationMediumSelectionNeeded;
          this.slotSelectionNeeded = data.slotSelectionNeeded;
          this.programHasGroup = data.programHasGroup;
          this.programSlots = data.programSlots;
        }
      })
      .finally(() => {
        this.$emit("loaded", this.batchItem);
        this.scrollTop();
        this.detailsLoading = false;
      });

  },
  watch: {
    programSlots( slots ){
      if( Array.isArray(slots) && slots.length === 1 ) {
        this.selected.program_slot_id = slots[0].id;
      }
    }
  }

};
</script>

<style scoped>
</style>
